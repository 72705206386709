/**
 * Created by giovanni on 29-10-16.
 */
import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import $ from 'jquery';
import 'slick-carousel-latest';

const DecoLamp = {
	getData: function (catalogoName, callBack) {
		if (typeof DecoLamp.Data[catalogoName] === "object") {
			this.Setting.makeDigest = false;
			callBack(DecoLamp.Data);

		} else {
			$.getJSON("db/" + catalogoName + ".json", function (d) {
				DecoLamp.Data[catalogoName] = d[catalogoName];
				DecoLamp.Setting.makeDigest = true;
				if (typeof callBack === "function") {
					callBack(d);
				}
			});
		}
	},
	Setting: {
		makeDigest: false
	},
	Data: {}
};

const decoApp = angular.module("decolapp", [uiRouter]);

decoApp.config(function ($stateProvider, $urlRouterProvider) {

	$urlRouterProvider.otherwise("/");

	$stateProvider
		.state("listaProductos", {
			url: "/Productos/:Producto",
			templateUrl: "pages/Catalogo.html",
			controller: "ProductosController"
		})
		.state("productoParticular", {
			url: "/Productos/:Producto/:Marca/:id",
			templateUrl: "pages/Producto.html",
			controller: "ProductosController"
		})
		/*.state("productoParticular.template", {
		 templateUrl: "pages/MenuPrincipal.html"
		 })*/
		.state("Home", {
			url: "/",
			templateUrl: "pages/MenuPrincipal.html",
			controller: "MenuPrincipalController"
		});
});

decoApp.controller("MenuPrincipalController", [function () {

	$('#carrusel').slick({
		speed: 500,
		fade: true,
		autoplay: true,
		arrows: false,
		cssEase: 'linear'/*,
		variableWidth: true*/
	});

	// @ts-ignore
	gtag('config', 'UA-111455288-1', {
		'page_title': 'Homepage',
		'page_path': '/'
	});
}]);

decoApp.controller("ProductosController", ['$scope', '$stateParams', '$sce', function ($scope, $stateParams, $sce) {
	let I = {id: "", Producto: "", Marca: "", logoMarca: ""};
	I = $.extend(I, $stateParams);

	scrollTo(0, 0);

	$scope.I = I;


	const path = I.Marca === "" ? '/Productos/' + I.Producto : '/Productos/' + I.Producto + "/" + I.Marca + "/" + I.id;

	// @ts-ignore
	gtag('config', 'UA-111455288-1', {
		'page_title': I.Producto,
		'page_path': path
	});

	console.log(I);

	DecoLamp.getData(I.Producto, function (data) {


		if (I.id !== "") { //No hay catalogo cargado
			const d = data["Marcas"][I.Marca]["items"][I.id];
			d.desc = $sce.trustAsHtml(d.desc);

			$scope.itemData = d;

			//$.get("db/productos/Iluminacion_Comercial/prod.html", function (data) {
			$.get("db/productos/" + I.Producto + "/" + I.id + ".html", function (data) {
				$(".contentProducto").html(data);
			});
			I.logoMarca = data["Marcas"][I.Marca]["logo"];

		} else {
			$scope.C = data;
		}

		if (DecoLamp.Setting.makeDigest) {
			$scope.$apply();
		}
	});

}]);
